import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { DataConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AlertService } from './api/alert.service';
import { AlertStateService } from './api/alert-state.service';
import { AlertTimeOutOfRangeService } from './api/alert-time-out-of-range.service';
import { AnalyticsTelemetryService } from './api/analytics-telemetry.service';
import { LastTelemetryService } from './api/last-telemetry.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class DataApiModule {
    public static forRoot(configurationFactory: () => DataConfiguration): ModuleWithProviders<DataApiModule> {
        return {
            ngModule: DataApiModule,
            providers: [ { provide: DataConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: DataApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('DataApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
